@import '../../styles/variables';
@import '~bootstrap/scss/mixins';

$_header-height: 64px;
$_sidebar-width: 240px;
$_zindex-header: 100;

////////////////////////////////////////////////////////////////////
// page

.page {
  display: flex;
  //padding-top: $_header-height;
  min-height: 100vh;
  flex-direction: column;
  //background-color: $gray-100;
}

.page--has-header {
  padding-top: $_header-height;
}

////////////////////////////////////////////////////////////////////
// page__header

.page__header {
  background-color: #ffffff;
  border-bottom: 1px solid $gray-300;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $_zindex-header;

  .navbar {
    width: 100%;
  }

  .nav__vertical-divider {
    height: 25px;
    width: 0;
    margin: 0 1rem;
    overflow: hidden;
    border-left: 2px solid #e9ecef;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

////////////////////////////////////////////////////////////////////
// page__center
.page__center {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 65px);
  width: 100%;
  height: 100%;
}

////////////////////////////////////////////////////////////////////
// page__main

.page__main {
  position: relative; // *important: page loading will be {position: absolute}
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  //min-height: 100vh;
}

////////////////////////////////////////////////////////////////////
// page__sidebar
.page__sidebar {
  min-height: calc(100vh - 65px);

  &__inner {
    position: fixed;
    top: $_header-height;
    background-color: #ffffff;
    border-right: 1px solid $gray-200;
    z-index: 99;
    height: calc(100vh - 65px);
    overflow: hidden;
    overflow-y: auto;
  }

  &--left {
    border-right: 1px solid $gray-200;
  }

  &--right {
    border-left: 1px solid $gray-200;
  }
}

////////////////////////////////////////////////////////////////////
// page__footer
.page__footer {
  margin-top: auto;
  padding: 2rem 0;
  background-color: #fff;
  border-top: 1px solid $gray-300;
  color: rgba(0, 0, 0, 0.5);

  ul {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
  }

  a {
    color: rgba(0, 0, 0, 0.5);
    line-height: 2;
    white-space: nowrap;
    &.active,
    &:hover {
      // &:active {
      color: $primary;
    }
  }

  a[class='active'] {
    color: $primary;
  }
}

.page__backdrop {
  top: 0;
  left: 0;
  z-index: 10; // 999
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

////////////////////////////////////////////////////////////////////
// Notifications

.notifications__badge {
  position: absolute;
  border-radius: 1rem;
  background-color: $danger;
  line-height: 1rem;
  color: $white;
  padding: 0 5px;
  font-size: $font-size-sm;
  font-weight: bold;
  top: 0;
  left: 55%;
}

.notifications__list {
  min-height: 100px;
  max-height: 300px;
  width: 320px;
  overflow-y: auto;
}

.notifications__item {
  padding: 10px 18px;
  white-space: normal;
  display: flex;
  align-items: flex-start;

  .notification__icon {
    margin-right: 1rem;
  }

  .notification__icon--error {
    color: #e04552;
  }

  .notification__icon--success {
    color: #2bcac9;
  }

  .notification__icon--warning {
    color: #ffc107;
  }

  .notification__icon--password {
    color: #2146da;
  }

  .notification__icon--enabled {
    color: #efbc21;
  }

  .notification__icon--disabled {
    color: #cec6c7;
  }

  .notification__icon--cert {
    color: #2bcac9;
  }

  .notification__icon--enquiry {
    color: #2bcac9;
  }

  .notification__icon--company {
    color: #82c91e;
  }
}

.notifications__item--empty {
  min-height: 90px;
  text-align: center;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.notifications__item--unread {
  background-color: white;
  cursor: pointer;
}

.notifications__item--read {
  background-color: $gray-100;
  color: $gray-600;
}

.notifications__text {
  flex-grow: 1;

  .notifications__text--message {
    font-size: $font-size-base;
    line-height: 1.2;
  }

  .notifications__text--date {
    font-size: $font-size-sm;
    text-align: left;
    color: $gray-600;
    font-style: italic;
  }
}

.search__type {
  width: 12rem !important;
}

.search__forms {
  width: 45rem !important;
  margin-left: 2rem;
}

.form-control-select {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-left: 1rem;
  width: 9rem;
}

.form-control__select {
  background-color: white !important;
  border-color: #ced4da !important;
  color: black !important;
  width: 10rem;

  &:focus {
    background-color: white !important;
    border-color: #0f579f !important;
    color: black !important;
  }

  &:hover {
    background-color: #94979b;
    border-color: #07437e;
    color: black;
  }
}

.form-control-search {
  border-radius: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 30rem;
}

//  Captcha Modal

.captcha__modal-body {
  max-width: 18%;
  border-radius: 30px;
}

.dropdown-item {
  font-size: 14px;
}
