@import '../../styles/variables';

.label-status {
  white-space: nowrap;
}

.account-role {
  font-weight: $font-weight-normal !important;
  padding: 8px 1rem !important;
  font-size: $font-size-base !important;
}

.account-role--sm {
  padding: 6px 10px !important;
  font-size: $font-size-sm !important;
}

.account-role--super_admin {
  background-color: #107ad9 !important;
}

.account-role--admin {
  background-color: $indigo !important;
}

.account-role--staff {
  background-color: $orange !important;
}

// Country
.label-country {
}

// Company Type
.company-type {
  padding: 8px 1rem;
  font-weight: $font-weight-normal;
  font-size: $font-size-base;
}

.company-type--ab {
  background-color: #107ad9;
}

.company-type--admin {
  background-color: $indigo;
}

.company-type--staff {
  background-color: $orange;
}
