@import '../../styles/variables';
@import '~bootstrap/scss/mixins';

.header {
  width: 100%;
  font-size: 14px;
  top: 0;
  left: 0;

  .header-nav {
    max-width: 1400px;
    margin: 0 auto;
  }

  .header-nav a {
    font-size: 14px;
  }
}

.header--fluid {
  .header-nav {
    max-width: 100%;
    margin: 0 auto;
  }
}

.header--light {
  background-color: #fff;
  border-bottom: 1px solid rgb(232, 232, 232);
}

.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
