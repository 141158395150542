@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,400i,600,700,900);
@import url(https://img.iafcertsearch.org/r/flags/24/flags.min.css);
.styles_error-page__2YsBf {
  background: #023b89 url("https://img.iafcertsearch.org/r/p/public/img_geo.png") no-repeat right bottom;
  text-align: center;
  padding: 5rem 0;
  flex-grow: 1;
  flex-direction: column;
}
.styles_error-page__2YsBf .styles_error-msg__3RIvA {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 100;
}
.styles_error-page__2YsBf .styles_error-msg__icon__2R3IO {
  color: #f7e800;
}
.styles_error-page__2YsBf h1 {
  font-size: 11rem;
  color: #fff;
  font-family: "Helvetica Neue";
  line-height: 1;
}
.styles_error-page__2YsBf h2 {
  font-size: 3rem;
  color: #fff;
  line-height: 2;
}
.styles_error-page__2YsBf .styles_btn-sm__3yqvq {
  padding: 0.75rem 4.5rem;
}
.styles_private-sidebar__3vE2O {
  background: #0077c0;
  height: 100%;
  padding: 1rem 0;
  overflow: hidden;
  overflow-y: auto;
}
.styles_private-sidebar__3vE2O small {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.55);
  display: block;
  margin: 1.5rem 1rem;
}
.styles_private-sidebar__3vE2O ul {
  list-style: none;
  margin: 0.75rem 0;
  padding: 0;
}
.styles_private-sidebar__3vE2O hr {
  margin: 1rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.styles_private-sidebar__3vE2O a {
  position: relative;
  display: block;
  padding: 0.75rem 1rem;
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
  transition: color 0.5s;
}
.styles_private-sidebar__3vE2O a:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 3px;
  background-color: #fff;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.35s;
}
.styles_private-sidebar__3vE2O a:hover {
  color: #ffffff;
}
.styles_private-sidebar__3vE2O a:hover:after {
  opacity: 1;
}
.styles_private-sidebar__3vE2O a[class=active] {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.035);
}
.styles_private-sidebar__3vE2O a[class=active]:after {
  opacity: 1;
}

.styles_local-layout__29EXp {
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
}

.styles_local-layout__header__UiLQE {
  border-bottom: 1px solid #dee2e6;
  position: fixed;
  width: 100%;
  top: 0;
  background: #fff;
  z-index: 10;
}

.styles_local-layout__sidebar__1ZHI0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  padding: 0 15px;
}

.styles_local-layout__sidebar__1ZHI0 {
  background: linear-gradient(#0077c0 0%, #0e4a94 100%);
  color: #fff;
}

.styles_local-layout__sidebar__inner__3WobL {
  max-width: 320px;
  margin: 0 auto;
}

.styles_local-layout__sidebar__text__1QKT6 {
  margin-bottom: 2rem;
}

.styles_local-layout__content__3Pan4 {
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.styles_local-layout__content--register__2ybDJ {
  flex-grow: 1;
  display: flex;
  background-color: #ffffff;
  padding-top: 4em;
}

.styles_local-layout__form__2D7RT {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 3rem 0 3rem 0;
}

.styles_local-layout__content__inner__KjiWp {
  margin: 0 auto;
  padding: 3rem 0 3rem 0;
}

.styles_local-layout__content__inner--form__3bzGz {
  width: 100%;
  max-width: 550px;
}

.styles_local-layout__form__title__1dLdz {
  text-align: center;
  color: #343a40;
  font-size: 28px;
}

.styles_local-layout__form__text__3MRvT {
  text-align: center;
  font-size: 18px;
  color: #6c757d;
  margin-bottom: 3rem;
}
.styles_image-background__17mzP {
  background: #cccccc no-repeat center center;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.styles_image-avatar__1sG5I {
  border: 1px solid #dee2e6;
}

.styles_image-mark--sm__2Hoef {
  height: 72px;
}

.styles_image-mark--md__3hdRk {
  height: 96px;
}

.styles_image-mark--lg__18zUm {
  height: 120px;
}

.styles_image-profile--rounded__1PeTM {
  border-radius: 50%;
}

.styles_image-profile--border__1R3a- {
  border: 1px solid #dee2e6;
}

.styles_image-profile--sm__6nwt8 {
  height: 32px;
  width: 32px;
}

.styles_image-profile--md__2fJm0 {
  width: 40px;
  height: 40px;
}

.styles_image-profile--lg__3WsYA {
  width: 100px;
  height: 100px;
}

.styles_image-company-logo__2xU1J {
  display: flex;
  text-align: center;
  overflow: hidden;
  align-items: center;
  justify-items: center;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.styles_image-company-logo__2xU1J img {
  width: 100%;
  height: auto;
  display: inline-block;
}

.styles_image-company-logo--bordered__3EYFq {
  padding: 4px;
  border: 1px solid #dee2e6;
}

.styles_image-company-logo--sm__3MsAM {
  width: 48px;
  height: 48px;
}

.styles_image-company-logo--md__3299k {
  width: 100px;
  height: 100px;
}

.styles_image-company-logo--lg__3VurP {
  width: 140px;
  height: 140px;
}

.styles_image-logo__ioyKW {
  width: auto;
}

.styles_image-logo--sm__2F-sr {
  height: 36px;
}

.styles_image-logo--md__1FbCt {
  width: auto;
  height: 48px;
}

.styles_image-logo--lg__P63rN {
  width: 128px;
  height: auto;
}

.styles_image-logo-qt__1rsnn {
  height: auto;
}

.styles_image-logo-qt--sm__3pc1c {
  width: 120px;
}

.styles_image-logo-qt--md__1R3iq {
  width: 198px;
}

.styles_image-logo-qt--lg__2EYfc {
  width: 198px;
}

.styles_image-logo-qt--block__28b5M {
  display: block;
}

.styles_image-logo-iaf__2-Cwu {
  width: 150px;
  height: auto;
}
.styles_loading-container__294pC {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  flex-direction: column;
}

.styles_page__loading__backdrop__p4QGc {
  top: 0;
  left: 0;
  z-index: 999;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}
.styles_page__2giPd {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.styles_page--has-header__IKuz3 {
  padding-top: 64px;
}

.styles_page__header__3O0di {
  background-color: #ffffff;
  border-bottom: 1px solid #dee2e6;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.styles_page__header__3O0di .styles_navbar__2Sn_q {
  width: 100%;
}
.styles_page__header__3O0di .styles_nav__vertical-divider__1M1TQ {
  height: 25px;
  width: 0;
  margin: 0 1rem;
  overflow: hidden;
  border-left: 2px solid #e9ecef;
}
@media (max-width: 991.98px) {
  .styles_page__header__3O0di .styles_nav__vertical-divider__1M1TQ {
    display: none;
  }
}

.styles_page__center__1vQjj {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 65px);
  width: 100%;
  height: 100%;
}

.styles_page__main__1AyMs {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.styles_page__sidebar__15Y9i {
  min-height: calc(100vh - 65px);
}
.styles_page__sidebar__inner__1aeqN {
  position: fixed;
  top: 64px;
  background-color: #ffffff;
  border-right: 1px solid #e9ecef;
  z-index: 99;
  height: calc(100vh - 65px);
  overflow: hidden;
  overflow-y: auto;
}
.styles_page__sidebar--left__j_wXK {
  border-right: 1px solid #e9ecef;
}
.styles_page__sidebar--right__FdG1d {
  border-left: 1px solid #e9ecef;
}

.styles_page__footer__1ZHAO {
  margin-top: auto;
  padding: 2rem 0;
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  color: rgba(0, 0, 0, 0.5);
}
.styles_page__footer__1ZHAO ul {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}
.styles_page__footer__1ZHAO a {
  color: rgba(0, 0, 0, 0.5);
  line-height: 2;
  white-space: nowrap;
}
.styles_page__footer__1ZHAO a.styles_active__35ah_, .styles_page__footer__1ZHAO a:hover {
  color: #0087d3;
}
.styles_page__footer__1ZHAO a[class=active] {
  color: #0087d3;
}

.styles_page__backdrop__1sOht {
  top: 0;
  left: 0;
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.styles_notifications__badge__1iH_Y {
  position: absolute;
  border-radius: 1rem;
  background-color: #dc3545;
  line-height: 1rem;
  color: #fff;
  padding: 0 5px;
  font-size: 0.75rem;
  font-weight: bold;
  top: 0;
  left: 55%;
}

.styles_notifications__list__ustTJ {
  min-height: 100px;
  max-height: 300px;
  width: 320px;
  overflow-y: auto;
}

.styles_notifications__item__3T8iK {
  padding: 10px 18px;
  white-space: normal;
  display: flex;
  align-items: flex-start;
}
.styles_notifications__item__3T8iK .styles_notification__icon__19OdQ {
  margin-right: 1rem;
}
.styles_notifications__item__3T8iK .styles_notification__icon--error__1xdGl {
  color: #e04552;
}
.styles_notifications__item__3T8iK .styles_notification__icon--success__2FLmR {
  color: #2bcac9;
}
.styles_notifications__item__3T8iK .styles_notification__icon--warning__3wxHC {
  color: #ffc107;
}
.styles_notifications__item__3T8iK .styles_notification__icon--password__ZbpTV {
  color: #2146da;
}
.styles_notifications__item__3T8iK .styles_notification__icon--enabled__69Lbs {
  color: #efbc21;
}
.styles_notifications__item__3T8iK .styles_notification__icon--disabled__2CoSN {
  color: #cec6c7;
}
.styles_notifications__item__3T8iK .styles_notification__icon--cert__5yG38 {
  color: #2bcac9;
}
.styles_notifications__item__3T8iK .styles_notification__icon--enquiry__1OvjY {
  color: #2bcac9;
}
.styles_notifications__item__3T8iK .styles_notification__icon--company__I390X {
  color: #82c91e;
}

.styles_notifications__item--empty__6YXFJ {
  min-height: 90px;
  text-align: center;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.styles_notifications__item--unread__1irEi {
  background-color: white;
  cursor: pointer;
}

.styles_notifications__item--read__TsXYN {
  background-color: #f8f9fa;
  color: #6c757d;
}

.styles_notifications__text__2TYhk {
  flex-grow: 1;
}
.styles_notifications__text__2TYhk .styles_notifications__text--message__Dpsat {
  font-size: 0.875rem;
  line-height: 1.2;
}
.styles_notifications__text__2TYhk .styles_notifications__text--date__1hxJ4 {
  font-size: 0.75rem;
  text-align: left;
  color: #6c757d;
  font-style: italic;
}

.styles_search__type__2hCKr {
  width: 12rem !important;
}

.styles_search__forms__1HVMe {
  width: 45rem !important;
  margin-left: 2rem;
}

.styles_form-control-select__3r8WB {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-left: 1rem;
  width: 9rem;
}

.styles_form-control__select__N6NbV {
  background-color: white !important;
  border-color: #ced4da !important;
  color: black !important;
  width: 10rem;
}
.styles_form-control__select__N6NbV:focus {
  background-color: white !important;
  border-color: #0f579f !important;
  color: black !important;
}
.styles_form-control__select__N6NbV:hover {
  background-color: #94979b;
  border-color: #07437e;
  color: black;
}

.styles_form-control-search__1SM0p {
  border-radius: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 30rem;
}

.styles_captcha__modal-body__2sQ4o {
  max-width: 18%;
  border-radius: 30px;
}

.styles_dropdown-item__2AD06 {
  font-size: 14px;
}
.styles_loading-container__1aunk {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  flex-direction: column;
}

.styles_page__loading__backdrop__JQ5HC {
  top: 0;
  left: 0;
  z-index: 999;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}
.PageHeader_header__3vJNe {
  width: 100%;
  font-size: 14px;
  top: 0;
  left: 0;
}
.PageHeader_header__3vJNe .PageHeader_header-nav__18pr3 {
  max-width: 1400px;
  margin: 0 auto;
}
.PageHeader_header__3vJNe .PageHeader_header-nav__18pr3 a {
  font-size: 14px;
}

.PageHeader_header--fluid__2SG2y .PageHeader_header-nav__18pr3 {
  max-width: 100%;
  margin: 0 auto;
}

.PageHeader_header--light__3ejcV {
  background-color: #fff;
  border-bottom: 1px solid rgb(232, 232, 232);
}

.PageHeader_header--fixed__3Zk-r {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.styles_label-status__1yRD6 {
  white-space: nowrap;
}

.styles_account-role__M9j_Z {
  font-weight: 400 !important;
  padding: 8px 1rem !important;
  font-size: 0.875rem !important;
}

.styles_account-role--sm__1p4iI {
  padding: 6px 10px !important;
  font-size: 0.75rem !important;
}

.styles_account-role--super_admin__Ctzf- {
  background-color: #107ad9 !important;
}

.styles_account-role--admin__WZ5XW {
  background-color: #6610f2 !important;
}

.styles_account-role--staff__2QVSD {
  background-color: #fd7e14 !important;
}

.styles_company-type__2dq_1 {
  padding: 8px 1rem;
  font-weight: 400;
  font-size: 0.875rem;
}

.styles_company-type--ab__3Ivug {
  background-color: #107ad9;
}

.styles_company-type--admin__3Aj85 {
  background-color: #6610f2;
}

.styles_company-type--staff__3p-8r {
  background-color: #fd7e14;
}
.styles_version-content__header__1mNRn {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  max-height: auto;
  height: 4em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_version-content-table__1je_A {
  max-height: 560px;
  overflow: auto;
}
.styles_version-content-table__1je_A table {
  border-collapse: separate;
}
.styles_version-content-table__1je_A table thead {
  background-color: #fff;
  border: none;
  position: sticky;
  top: 0;
  z-index: 2;
}
.styles_version-content-table__1je_A table thead:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #dee2e6;
  position: absolute;
  margin-bottom: 0;
}
.styles_version-content-table__1je_A table .styles_cell-color__394Mw {
  background-color: rgba(0, 0, 0, 0.075);
}

.styles_version-badge__2_MU4 {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 100%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  margin-right: 0.5em;
}
.styles_version-badge--primary__1ApFz {
  background-color: rgba(0, 135, 211, 0.2);
  color: #0087d3;
}
.styles_version-badge--secondary__3gTsX {
  background-color: rgba(108, 117, 125, 0.2);
  color: #6c757d;
}
.styles_version-badge--success__EBazW {
  background-color: rgba(40, 167, 69, 0.2);
  color: #28a745;
}
.styles_version-badge--info__2JETP {
  background-color: rgba(23, 162, 184, 0.2);
  color: #17a2b8;
}
.styles_version-badge--warning__UlqJX {
  background-color: rgba(255, 193, 7, 0.2);
  color: #ffc107;
}
.styles_version-badge--danger__26SSA {
  background-color: rgba(220, 53, 69, 0.2);
  color: #dc3545;
}
.styles_version-badge--light__rUhKQ {
  background-color: rgba(248, 249, 250, 0.2);
  color: #f8f9fa;
}
.styles_version-badge--dark__Fkwy8 {
  background-color: rgba(52, 58, 64, 0.2);
  color: #343a40;
}

.styles_template-version-card__3N-1e {
  border: 1px solid #dee2e6;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  white-space: pre;
  min-width: 150px;
}
